<template>
  <div></div>
</template>
<script>
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
        sg: null,
        gn: null,
        state: 0
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {
      }
    },
    watch: {
      senceGuid(val, oldval) {
        console.log('senceGuid Change')
      },
      groupGuid(val, oldval) {
        console.log('groupGuid Change')
      }
    },
    computed: {
      tour() {
        return this.publicData.tour || {}
      },
      senceGuid() {
        return this.publicData.senceGuid
      },
      groupGuid() {
        return this.publicData.groupGuid
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          tourIn: {
            name: 'renewSence',
            arg: 'tourIn'
          },
          groupIn: {
            name: 'renewSence',
            arg: 'groupIn'
          },
          panoIn: {
            name: 'renewSence',
            arg: 'itemIn'
          },
          autoTourIn: {
            name: 'renewSence',
            arg: 'autoTour'
          },
          //  inComplete: {
          //    name: 'renewSence',
          //    arg: 'default'
          //  }
        },
        funcs: {
          nextView: this.nextView,
          inGroup: this.inGroup,
          inPano: this.inPano,
        }
      })
      this.sg = this.senceGuid
      this.gn = this.groupGuid
      this.nextView()
    },
    destroyed() {
    },
    methods: {
      inPano() {
        console.log('inPano')
        this.state = 2
        setTimeout(this.nextView, 1)
      },
      inGroup() {
        console.log('inGroup')
        this.state = 1
        setTimeout(this.nextView, 1)
      },
      getGroup(groupGuid) {
        console.log(groupGuid, this.groups)
        for (var i in this.tour.groups) {
          if (this.tour.groups[i].guid == groupGuid) {
            return this.tour.groups[i]
          }
        }
      },
      setIntro(intro) {
        this.getFunc('changePublicData')({ intro })
      },
      nextView() {
        console.log('next', this.state)
        if (this.tour && this.tour.autoTour && this.tour.autoTour.acts && this.tour.autoTour.acts.length > 0 && this.state < 1) {
          this.$emit('autoTourIn')
          this.state = 4;
          return
        }
        if (this.getFunc({ name: 'isInVRMode', target: 'FullPano' })()) {
          this.$emit('VRModeIn')
          this.state = 4;
          return
        }
        var groupCount = sessionStorage.getItem(this.tour.guid + this.groupGuid) || 0
        var itemCount = sessionStorage.getItem(this.tour.guid + this.senceGuid) || 0
        console.log(this.hasPanoIn(), this.state < 3, itemCount < 1)
        if (this.hasTourIn() && this.state < 1) {
          this.setIntro(this.tour)
          this.$emit('tourIn')
          this.state = 1;
        } else if (groupCount < 1 &&this.hasGroupIn() && this.state < 2) {
          var g = this.groupGuid
          var group = this.getGroup(g) || {}
          console.log(group)
          this.setIntro({ introImage: group.file, title: group.title, logo: this.tour.logo, text: group.description, firstImage: this.tour.firstImage, isPro: this.tour.isPro })
          this.$emit('groupIn')
          sessionStorage.setItem(this.tour.guid + this.groupGuid, groupCount + 1)
          this.state = 2;
        } else if (itemCount<1&& this.hasPanoIn() && this.state < 3) {
          var g = this.senceGuid
          var pano = this.getFunc({ name: 'getPano', target: 'Data' })(g)
          this.setIntro({ introImage: pano.file, title: pano.title, logo: this.tour.logo, text: pano.description, firstImage: this.tour.firstImage, isPro: this.tour.isPro })
          this.$emit('panoIn')
          sessionStorage.setItem(this.tour.guid + this.senceGuid, itemCount + 1)
          this.state = 3;
        } else {
          var g = this.groupGuid
          var group = this.getGroup(g) || {}
          console.log('inend group', g, group)
          if (group.type == 'Blog') {
            this.getFunc('renewSence')('blog')
          } else if (group.type == 'Gallery') {
            this.getFunc('renewSence')('gallery')
          } else {
            this.getFunc('renewSence')('default')
            //  this.routerto(groupItems[0])
          }
          this.$emit('inComplete')
          this.state = 4;
        }
      },
      hasTourIn() {
        if (this.tour.introImage || this.tour.description) {
          return true
        }
      },
      hasGroupIn(groupGuid) {
        var g = groupGuid || this.groupGuid
        var group = this.getGroup(g) || {}
        console.log(g, group)
        if (group.description || group.file) {
          return true
        }
      },
      hasPanoIn(senceGuid, groupGuid) {
        var gg = groupGuid || this.groupGuid
        var group = this.getGroup(gg) || {}
        console.log('hasPanoIn', gg, group)
        if (group.type == 'Blog' || group.type == 'Gallery') {
          return false
        }
        var g = senceGuid || this.senceGuid
        var pano = this.getFunc({ name: 'getPano', target: 'Data' })(g) || {}
        console.log(pano)
        if (pano.description || pano.file) {
          return true
        }
      },
      groupToIntro(group) {
        return { title: group.title, text: group.text, introImage: group.file }
      },
    },
  }
</script>
<style scoped>
  .panoTitle-enter-active {
    animation: panoTitle-in .5s;
  }

  .panoTitle-leave-active {
    animation: panoTitle-in .5s reverse;
  }

  @keyframes panoTitle-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.5);
    }

    100% {
      transform: scale(1);
    }
  }

  .panoTitle {
    pointer-events: none !important;
    background-image: linear-gradient(to bottom, #0005,#0002, #0000);
    text-shadow: 1px 1px 2px black;
    color: white
  }

    .panoTitle h1 {
      margin: 10px 0;
      font-size: 1.4rem;
    }
</style>
